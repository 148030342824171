@font-face {
  font-family: "apocSansLight";
  src: url("../public/ApocSans-Light.otf") format("opentype");
}

@font-face {
  font-family: "surtLightExp";
  src: url("../public/Surt-LightExp.otf") format("opentype");
}

@font-face {
  font-family: "helveticaNeueLight";
  src: url("../public/HelveticaNeueLTStd-Lt.otf") format("opentype");
}

/*----RESETS----*/
h1,
h2,
h3,
p,
th {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

h1,
h2,
h3 {
  line-height: 1;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
img,
b,
u,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
footer,
header,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*-------------*/

/*----VARS----*/
:root {
  --bg-color: #1f363f;
  --bg-color-shade: #1f2a2e;
  --bg-color-black: #000;
  --font-color: #ffffff;
  --accent-color: #fff575;
  --padding: 40px;
  --header-font-size: 50px;
  --header-font-size-mobile: 30px;
  --p-font-size: 22px;
  --p-font-size-mobile: 18px;
  --a-font-size: 30px;
  --a-font-size-mobile: 20px;
  --small-font-size: 16px;
}
/*-------------*/

/*----ANIMATIONS----*/
@keyframes dash {
  from {
    stroke-dashoffset: 658;
  }
  to {
    stroke-dashoffset: 0;
    fill: black;
  }
}

.path {
  /* stroke-dasharray: 658; */
  /* stroke-dasharray: 658; */
  /* animation: dash 5s linear forwards; */
}

@keyframes fade-in-content {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 1;
	}
}
/*-------------*/

/*----GLOBAL----*/
html {
  scroll-behavior: smooth;
  background-color: var(--bg-color);
}

a {
  color: var(--font-color);
  text-decoration: none;
}

a:hover {
  color: var(--accent-color);
}
/*-------------*/

/*----CUSTOM----*/
.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: var(--padding) 0;
}

.nav-bar-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 18vh;
  font-family: apocSansLight;
  font-size: var(--a-font-size);
  color: var(--font-color);
  text-transform: uppercase;
  padding-bottom: 30px;
}

.nav-bar-items-wrapper {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-items-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
}


.nav-bar-items-wrapper a:not(:last-of-type)::after {
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--accent-color);
  margin: 0 12px;
}

.logo {
  position: absolute;
  left: 0;
  background: var(--accent-color);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  padding: 10px;
}

.logo img {
  width: 100%;
  height: auto;
}

.layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80%;
  max-width: 1400px;
  animation: fade-in-content 5s ease;
}

.cta-button {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.section:not(:first-of-type) {
  padding-top: 50px;
}

.section:last-of-type {
  padding-bottom: 50px;
}

.section a {
  font-family: apocSansLight;
  font-size: var(--a-font-size);
  text-transform: uppercase;
  color: var(--accent-color);
}

.section h2 {
  font-family: apocSansLight;
  text-transform: uppercase;
  font-size: var(--header-font-size);
  padding-bottom: 30px;
  color: var(--accent-color);
}

.section p {
  font-size: var(--p-font-size);
  font-family: helveticaNeueLight;
  font-weight: initial;
  line-height: 1.6;
  padding-bottom: 30px;
}

.contentTile-text {
  width: 40%;
  max-width: 50%;
}

.contentTile-text.contentTile--right {
  padding-left: var(--padding);
}

.contentTile-text.contentTile--left {
  padding-right: var(--padding);
}

.contentTile-img {
  width: 60%;
  max-width: 60%;
  display: flex;
  justify-content: flex-end;
}

.contentTile--right {
  text-align: right;
}

.contentTile--left {
  text-align: left;
}

.gallerySection-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.gallerySection-row {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  align-items: flex-start;
  margin-bottom: var(--padding);

  max-width: 100vw;
  overflow: auto;
  white-space: nowrap;
}

.gallerySection-img {
  padding-right: var(--padding);
}

.processSection-wrapper {
  display: flex;
}

.processSection-col {
  padding-right: 60px;
  width: 100%;
}

.processSection-col h3 {
  font-family: apocSansLight;
  text-transform: uppercase;
  font-size: var(--header-font-size);
  padding-bottom: 30px;
  line-height: 1;
  color: var(--accent-color);
}

.section .formSection-context {
  color: white;
  font-size: var(--small-font-size);
}

.formSection-range {
  display: flex;
  justify-content: space-between;
  font-size: var(--p-font-size);
  padding-bottom: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: 50%;
  background: var(--bg-color-black);
  color: white;

  font-family: helveticaNeueLight;
  font-weight: initial;
  line-height: 1.6;

  padding: 30px;
  border-radius: 20px;
}

form button {
  font-family: helveticaNeueLight;
  background-color: white;
  color: black;
  font-size: var(--p-font-size);
  outline: none;
  border: none;
  padding: 10px 30px;
  border-radius: 26px;
  cursor: pointer;
  line-height: inherit;
}

form input[type=text],
form textarea {
  font-family: helveticaNeueLight;
  font-size: var(--p-font-size);

  color: white;
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 30px;
  outline: none;
}

form textarea {
  width: auto;
  resize: none;
}

form input {
  height: 40px;
}

form label {
  font-size: var(--p-font-size);
  color: var(--accent-color);
}

form p {
  font-size: 14px;
}

input[type="range"] {
  width: 50%;
}

input[type="range"]:focus {
  outline: none;
}

.spacer {
  display: block;
  width: 100%;
  height: 14vh;
}

footer {
  width: 100%;
  font-family: helveticaNeueLight;
  font-size: var(--small-font-size);
  text-align: center;
  padding-top: var(--padding);
}

/* Gallery Lightbox */
.react-images__footer__count {
  font-family: helveticaNeueLight;
}

.react-images__container button:focus {
  outline: none;
}



/*----MEDIA QUERIES----*/
@media screen and (max-width: 900px) {

  .page-container {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .layout-container {
    overflow-x: hidden;
  }

  .logo {
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  .nav-bar-container {
    flex-direction: row;
    height: auto;
    padding-top: 0;
    text-align: right;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding-bottom: 20px;
  }

  .nav-bar-items-wrapper {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }

  .nav-bar-items-wrapper a:not(:last-of-type)::after {
    display: none;
  }

  .layout-container,
  .contentTile-text,
  .contentTile-img,
  form,
  textarea {
    width: 100%;
    max-width: 100%;
  }

  textarea {
    padding: 0;
  }

  .contentTile-img {
    padding-bottom: 0;
    justify-content: center;
  }

  img {
    max-width: 100vw;
  }

  #about .contentTile-text {
    padding-bottom: 40px;
  }

  #process .contentTile-img {
    padding-bottom: 20px;
  }

  .contentTile-text {
    padding: 0;
  }

  .contentTile-text a {
    padding-left: 20px;
  }

  .contentTile-text.contentTile--left {
    padding-right: 0;
    text-align: left;
  }

  .contentTile-text.contentTile--right {
    padding-left: 0;
    text-align: left;
  }

  .section {
    flex-direction: column;
  }

  .section p, footer {
    padding-left: 20px;
    padding-right: 20px;
    font-size: var(--p-font-size-mobile);
  }

  .section p {
    padding-bottom: 20px;
  }

  .section h2 {
    font-size: var(--header-font-size-mobile);
    padding: 20px;
  }

  .section:not(:first-of-type) {
    padding-top: 0;
  }

  .section:last-of-type {
    padding-bottom: 20px;
  }

  a {
    font-size: var(--a-font-size-mobile);
  }

  .spacer {
    height: 0;
  }

  .processSection-wrapper {
    flex-direction: column;
  }

  .processSection-col h2 {
    font-size: var(--header-font-size-mobile);
    padding: 20px;
  }

  .gallerySection-row {
    position: relative;
    overflow: hidden;
  }

  footer {
    font-size: var(--p-font-size-mobile);
    padding: 18px;
  }

  form {
    padding: 20px 0;
    margin-top: 40px;
  }

  form input {
    height: auto;
  }

  form input[type="text"] {
    margin-left: 20px;
    margin-right: 20px;
  }

  .formSection-range {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  form,
  input,
  textarea {
    text-align: center;
  }

  .form button {
    line-height: inherit;
  }

  .section .formSection-context {
    font-size: var(--p-font-size-mobile);
  }

} 

/* INPUT SLIDER OVERWRITES */

input[type=range] {
  height: 24px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 50%;
  background: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 50%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: white;
  border-radius: 1px;
  border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--accent-color);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: white;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: none;
  background: var(--bg-color);
  border-radius: 1px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: none;
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--accent-color);
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: var(--bg-color);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background:var(--bg-color);
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--accent-color);
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--bg-color);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--bg-color);
}
